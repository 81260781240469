import React from 'react';
import ReactDOM from 'react-dom';
// import './global.css';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import App from './App';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      // Token expirado, redirecionar para a tela de login
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);