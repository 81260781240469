import React, { useEffect, useState } from 'react';
import './MyAccount.css'; // Importando o arquivo de estilo
import api from '../../services/api';

interface Produto {
  id: number;
  name: string;
  description: string;
  url: string;
  url_tutoriais: string;
  url_plan: string;
  url_download: string;
  url_download_alt: string;
  url_site: string;
  url_site_alt: string;
  url_sup: string;
  show: boolean;
}

interface UserInfo {
  id: number;
  name: string;
  email: string;
  cpf: string;
  paymentMethod: string;
  expiryDate: string;
  server: number;
  produto: number;
  subproduto: number;
}

interface UserProductInfo {
  name: string;
  email: string;
  produto: number; // Correspondente ao id do produto
  paymentMethod: string;
  expiryDate: string;
  server: number;
  subproduto: number;
}

const MyAccount: React.FC = () => {

  const userProductIds: number[] = JSON.parse(localStorage.getItem('userProductIds') || '[]');
  const userInfo: UserInfo[] = JSON.parse(localStorage.getItem('userInfo') || '[]');
  const [produtoSelecionado, setProdutoSelecionado] = useState<Produto | null>(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showModalLink, setShowModalLink] = useState(false);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [produtosAll, setProdutosAll] = useState<Produto[]>([]);
  const [produtoURL, setProdutoURL] = useState('')
  const [users, setUsers] = useState<UserProductInfo[]>([]);
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [senhaNaoCoincide, setSenhaNaoCoincide] = useState(false);
  const [cpf, setCpf] = useState('');
  const [cpfValido, setCpfValido] = useState(true);
  const [senhaCurta, setSenhaCurta] = useState(false);
  const [produtoAux, setProdutoAux] = useState<Produto | null>();
  const [cpfError, setCpfError] = useState('');
  const [senhaAlterada, setSenhaAlterada] = useState('');
  const [lastTwoDigitsOfCpf, setLastTwoDigitsOfCpf] = useState('');

  useEffect(() => {
    if(novaSenha){
        setSenhaCurta(novaSenha.length < 6 || confirmarSenha.length < 6);
    }
  }, [novaSenha, confirmarSenha]);
  

  useEffect(() => {
  if(confirmarSenha){
    setSenhaNaoCoincide(novaSenha !== confirmarSenha);
    }
  }, [novaSenha, confirmarSenha]);

  useEffect(() => {
    // Função para buscar produtos do backend
    const fetchProducts = async () => {
      try {
        const response = await api.get<Produto[]>('/search-product'); // Substitua pelo endpoint correto
        setProdutosAll(response.data)
        const productsToShow = response.data.filter((product: Produto) => product.show === true);
        setProdutos(productsToShow);
      } catch (error) {
        console.error('Erro ao buscar produtos:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (produtoSelecionado) {
      setShowDetails(false);
      const timeoutId = setTimeout(() => {
        setShowDetails(true);
      }, 500); // Ajuste o delay conforme necessário

      return () => clearTimeout(timeoutId);
    }
  }, [produtoSelecionado]);


  const handleMostrarDetalhes = (produto: Produto) => {
    if (produtoSelecionado && produtoSelecionado.id === produto.id) {
      setProdutoSelecionado(null);
    } else {
      // Aqui verificamos se o usuário possui o produto ou subproduto
      let usersWithProductOrSubProduct: UserProductInfo[] = [];
      
      const userProduct: UserInfo | undefined = userInfo.find((user) => user.produto === produto.id);
   

      userInfo.forEach((user) => {
        if (user.produto === produto.id || user.subproduto === produto.id) {
          const produtoSubproduto: Produto | undefined = produtosAll.find((produto) => produto.id === user.subproduto);
          if (userProduct && produtoSubproduto) {
            setProdutoSelecionado(produtoSubproduto);
            usersWithProductOrSubProduct.push(userProduct);
          } else if(userProduct){
            setProdutoSelecionado(produto);
            usersWithProductOrSubProduct.push(userProduct);
          }
        }
        
      });
      
      if(usersWithProductOrSubProduct.length === 0){
        setProdutoSelecionado(produto)
        if(userProduct){
          usersWithProductOrSubProduct.push(userProduct);
        }
        setShowProductModal(false)
      }

      setUsers(usersWithProductOrSubProduct);
    }
  };

  const getImageForProduct = (productId: number) => {
    try {
      const image = require(`../../images/products/${productId}.png`);
      return image.default || image;
    } catch (error) {
      // Em caso de erro, retorne uma imagem padrão ou faça algo apropriado
      return require('../../images/default-image.png').default;
    }
  };

  const handleAbrirModal = (produto: Produto) => {
    if (userProductIds.includes(produto.id)) {
      handleMostrarDetalhes(produto);
      setShowProductModal(false)

    } else if(produto.id === 3 || produto.id === 4){
      handleMostrarDetalhes(produto);
      setShowProductModal(false)
    } else {
      setProdutoURL(produto.url)
      setProdutoAux(produto)
      setShowProductModal(true)
    }
  };

  const handleFecharModal = () => {
    //setProdutoSelecionado(null);
    setShowProductModal(false);
    setShowModalLink(false)
  };

  const handleVoltar = () => {
    setNovaSenha('');
    setConfirmarSenha('');
    setCpf('');
    setCpfError('');
    setUsers([])
    setProdutoSelecionado(null);
  };

  const handleSaibaMais = () => {
    if (produtoURL) {
      window.open(produtoURL, '_blank'); // Abre a URL em uma nova guia
      setShowProductModal(false)
    }
  };
  
   const validarCPF = (cpf: string) => {
    if (typeof cpf !== 'string') return false;
    cpf = cpf.replace(/[\s.-]*/g, ''); // Remove espaços, pontos e traços
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;

    let soma = 0;
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf[i - 1]) * (11 - i);
    }
    let resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf[9])) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf[i - 1]) * (12 - i);
    }
    let resto2 = (soma * 10) % 11;
    if (resto2 === 10 || resto2 === 11) resto2 = 0;
    if (resto2 !== parseInt(cpf[10])) return false;

    return true;
  };
  
  const isSpecialCpf = (cpf: string) => ['0', '99999999999', '88888888888'].includes(cpf);

  const handleResetarSenha = async () => {
  if (isSpecialCpf(userInfo[0]?.cpf || '')) {
      if (!validarCPF(cpf)) {
        console.error('CPF inválido1');
        setCpfValido(false);
        return;
      }
  
    try {
        if (produtoSelecionado) {
          const userForReset = userInfo.find(user => user.subproduto === produtoSelecionado.id);
          if (userForReset) {
            const response = await api.post('/reset-senha', {
              productId: userForReset.produto,
              cpf,
              novaSenha,
              server: userForReset.server,
              email: userForReset.email
            });
            // Lidar com a resposta da API, como exibir uma mensagem de sucesso
            // Fechar o modal de reset de senha
            setShowResetModal(false);
            setSenhaAlterada('Senha alterada com sucesso!')
            // Limpar o campo do CPF
            setNovaSenha('');
            setConfirmarSenha('');
            setCpf('');
          }
        }
    } catch (error: any) {
      // Lidar com erros, como exibir uma mensagem de erro
       if(error.response.status === 401){
        setCpfError('CPF diferente do cadastrado!')
      }
      console.error('Erro ao resetar a senha', error);
      setNovaSenha('');
      setConfirmarSenha('');
      setCpf('');
      
       setTimeout(() => {
            setCpfError('');
          }, 5000);
      }
    }else {
      if (lastTwoDigitsOfCpf !== userInfo[0]?.cpf.slice(-2)) {
        console.error('CPF inválido');
        setCpfValido(false);
        return;
      }
      const completeCpf = userInfo[0]?.cpf.slice(0, 9) + lastTwoDigitsOfCpf

      try {
        if (produtoSelecionado) {
          const userForReset = userInfo.find(user => user.subproduto === produtoSelecionado.id);
          if (userForReset) {
            const response = await api.post('/reset-senha', {
              productId: userForReset.produto,
              cpf: completeCpf,
              novaSenha,
              server: userForReset.server,
              email: userForReset.email
            });
            // Lidar com a resposta da API, como exibir uma mensagem de sucesso
            // Fechar o modal de reset de senha
            setShowResetModal(false);
            setSenhaAlterada('Senha alterada com sucesso!')
            // Limpar o campo do CPF
            setNovaSenha('');
            setConfirmarSenha('');
            setCpf('');
          }
        }
      }catch (error: any) {
      // Lidar com erros, como exibir uma mensagem de erro
       if(error.response.status === 401){
        setCpfError('CPF diferente do cadastrado!')
      }
      console.error('Erro ao resetar a senha', error);
      setNovaSenha('');
      setConfirmarSenha('');
      setCpf('');
      
       setTimeout(() => {
            setCpfError('');
          }, 5000);
      }
    }
  };

  const handleLinkClick = (url: string | null) => {
    if (url === null) {
      setShowModalLink(true);
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <div>
      <div className="container">
        {produtoSelecionado && showDetails && !showProductModal && (
          <button className="back-button" onClick={handleVoltar}>
            Voltar
          </button>
        )}
        <h1 className={`title ${produtoSelecionado ? 'fade-out' : ''}`}>Minha Conta</h1>
        <h2 className={`subtitle ${produtoSelecionado ? 'fade-out' : ''}`}>Escolha o Produto</h2>
        <div className={`products-container ${produtoSelecionado ? 'fade-out' : ''}`}>
          {produtos.map((produto) => (
            <div
              key={produto.id}
              className="product-card"
              onClick={() => handleAbrirModal(produto)}
            >
              <img src={getImageForProduct(produto.id)} alt={`Produto ${produto.id}`} />
              <p className="product-name">{produto.name}</p>
              {/* <p className="product-description">{produto.description}</p> */}
            </div>
          ))}
        </div>
        {showProductModal ? (
          <div className="product-modal">
            <h3>Você não possui esse produto :(</h3>
            <p>Deseja adquiri-lo?</p>
            <button onClick={handleSaibaMais}>Saiba mais</button>
            <button onClick={() => handleFecharModal()}>Cancelar</button>

            {(produtoAux && produtoAux.id === 3 || produtoAux && produtoAux.id === 4) && (
              <button onClick={() => handleMostrarDetalhes(produtoAux)}>Continue</button>
            )}
          </div>
        ) : null
        }
        {showModalLink ? (
          <div className="product-modal">
            <h3>Esse produto não possui essa opção, tente outra!</h3>
            <button onClick={() => handleFecharModal()}>Fechar</button>
          </div>
        ) : null
        }
        {produtoSelecionado && showDetails && !showProductModal && (
          <div className={`details-container ${showDetails ? 'fade-in' : ''}`}>
            <div className="left-column">
              {produtoSelecionado && (produtoSelecionado.id !== 3 && produtoSelecionado.id !== 4) && (
                <>
                  {users.map((user, index) => (
                    <div key={index}>
                      <div className="data-section">
                        <h3>Dados Pessoais</h3>
                        <p>Email: {user.email}</p>
                        <p>Nome: {user.name}</p>
                      </div>
                      <div className="payment-section">
                        <h3>Forma de Pagamento</h3>
                        <p>Método: {user.paymentMethod}</p>
                      </div>
                      <div className="expiration-section">
                        <h3>Vencimento</h3>
                        <p>Data de Vencimento: {user.expiryDate}</p>
                      </div>
                    </div>
                  ))}
                </>
              )}
              <div className="download-section">
                <h3>Download do APP</h3>
                {produtoSelecionado && produtoSelecionado.id !== 2 && (
                  <>
                    <p>Link para download: <button onClick={() => handleLinkClick(produtoSelecionado?.url_download)}>Baixar APK</button></p>
                  </>
                )}
                {produtoSelecionado && produtoSelecionado.id === 2 && (
                <>
                  <p>Link para download: <button onClick={() => handleLinkClick(produtoSelecionado?.url_download_alt)}>OnlyFlix Play</button>
                  <button onClick={() => handleLinkClick(produtoSelecionado?.url_download)}>OnlyFlix</button></p>
                </>
              )}
              </div>
              <div className="website-section">
                <h3>Site</h3>
                {produtoSelecionado && produtoSelecionado.id !== 2 && (
                  <>
                    <p>Versão Web: <button onClick={() => handleLinkClick(produtoSelecionado?.url_site)}>Clique Aqui</button></p>
                  </>
                )}
                {produtoSelecionado && produtoSelecionado.id === 2 && (
                  <>
                  <p>Versão Web: <button onClick={() => handleLinkClick(produtoSelecionado?.url_site_alt)}>OnlyFlix Play</button>
                   <button onClick={() => handleLinkClick(produtoSelecionado?.url_site)}>Onlyflix</button></p>
                </>
              )}
              </div>
             </div>
            <div className="right-column">
              <div className="product-section">
                <h3>Detalhes do Produto</h3>
                <p>Nome: {produtoSelecionado.name}</p>
                <p>Descrição: {produtoSelecionado.description}</p>
              </div>
              {produtoSelecionado && (produtoSelecionado.id !== 3 && produtoSelecionado.id !== 4) && (
                <>
                  <div className="reset-section">
                    <h3>Resetar Senha</h3>
                    <p>Para resetar a senha, informe a nova senha no campo abaixo:</p>
                    <input type="password" placeholder="Nova Senha" value={novaSenha} onChange={(e) => setNovaSenha(e.target.value)} />
                    <input type="password" placeholder="Confirmar Senha" value={confirmarSenha} onChange={(e) => setConfirmarSenha(e.target.value)} />
                    {senhaCurta && <p className="error-message">A senha deve ter pelo menos 6 dígitos</p>}
                    {senhaNaoCoincide && <p className="error-message">As senhas não coincidem</p>}
                    {senhaAlterada && <p className="success-message">{senhaAlterada}</p>}
                    <button onClick={() => setShowResetModal(true)}>Resetar Senha</button>
                  </div>
                </>
              )}
              <div className='tutorial-section'>
                <h3>Tutoriais</h3>
                <p>Possui dúvidas a respeito do produto? <button onClick={() => handleLinkClick(produtoSelecionado?.url_tutoriais)}>Tutoriais</button></p>
              </div>
              <div className="support-section">
                <h3>Suporte</h3>
                <p>Está com algum problema? <button onClick={() => handleLinkClick(produtoSelecionado?.url_sup)}>Entre em contato</button></p>
              </div>
            </div>
            <div className="reset-password-modal" style={{ display: showResetModal ? 'block' : 'none' }}>
              <h3>Resetar Senha</h3>
              <p>Informe {isSpecialCpf(userInfo[0]?.cpf || '') ? 'o CPF completo' : 'os dois últimos dígitos do CPF'} no campo abaixo:</p>
              {isSpecialCpf(userInfo[0]?.cpf || '') ? (
                <input type="text" maxLength={11} value={cpf} onChange={(e) => setCpf(e.target.value)} />
              ) : (
                <p>CPF: {userInfo[0]?.cpf.slice(0, 9)}<input type="text" maxLength={2} value={lastTwoDigitsOfCpf} onChange={(e) => setLastTwoDigitsOfCpf(e.target.value)} /></p>
              )}
              {!cpfValido && <p className="error-message">CPF inválido</p>}
              {cpfError && <p className="error-message">{cpfError}</p>}
              <button onClick={handleResetarSenha}>Enviar</button>
              <button onClick={() => setShowResetModal(false)}>Cancelar</button>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default MyAccount;
