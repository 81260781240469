import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';


import LoginAccount from './pages/LoginAccount/LoginAccount';
import MyAccount from './pages/MyAccount/MyAccount';

const Routes: React.FC = () => {
 

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LoginAccount} />
        <Route exact path="/minha-conta" component={MyAccount} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
